import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import { HTMLContent } from '../components/Content'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import { BlogPostTemplate } from '../components/BlogPostTemplate'

const BlogPost = ({ data }) => {
  const { blog: post, jobs } = data

  const relevantJobs = post.frontmatter.jobTags
    ? jobs.edges
        .map(job => ({
          rating: job.node.frontmatter.tags.filter(tag =>
            post.frontmatter.jobTags.includes(tag)
          ).length,
          job,
        }))
        .filter(rated => rated.rating > 0)
        .sort(rated => -rated.rating)
        .slice(0, 3)
        .map(rated => rated.job)
    : null

  return (
    <Layout>
      <Hero
        title="Für Mitleser"
        subtitle="Lesen Sie, was wir zu sagen haben"
        img={data.hero.childImageSharp}
      />
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        //description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        author={post.frontmatter.author}
        jobs={relevantJobs}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    blog: PropTypes.object,
    jobs: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    blog: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        tags
        author {
          name
          email
        }
        jobTags
      }
    }
    jobs: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "job-offer" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            image {
              childImageSharp {
                gatsbyImageData(height: 256)
              }
            }
            title
            date(formatString: "MMMM DD, YYYY")
            tags
          }
        }
      }
    }
    hero: file(relativePath: { eq: "blog.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
  }
`
