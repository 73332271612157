import { Link } from 'gatsby'
import { kebabCase } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Gravatar from 'react-gravatar'
import CardItem from '../components/CardItem'
import Content from '../components/Content'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  author,
  helmet,
  jobs,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            {!author ? null : (
              <div>
                <figure className=" ml-0 image is-128x128">
                  <Gravatar
                    className="is-rounded"
                    email={author.email}
                    size={500}
                    default="mp"
                  />
                </figure>
                {author.email !== '' ? (
                  <a
                    href={`mailto:${
                      author.email
                    }?subject=BlogPost: ${encodeURIComponent(title)}`}
                  >
                    <span className="icon has-text-primary">
                      <i className="fas fa-envelope" />
                    </span>{' '}
                    <span className={author.name ? '' : 'is-hidden'}>
                      {author.name}
                    </span>
                  </a>
                ) : (
                  <p className={author.name ? '' : 'is-hidden'}>
                    {author.name}
                  </p>
                )}
              </div>
            )}
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <p>{description}</p>
            <PostContent content={content} />
            <br />
            {jobs && jobs.length ? (
              <section className="section">
                <div className="container">
                  <div className="columns is-multiline">
                    {jobs.map(({ node }) => (
                      <div key={node.id} className="column is-one-third">
                        <CardItem
                          image={node.frontmatter.image}
                          title={node.frontmatter.title}
                          date={node.frontmatter.date}
                          tags={node.frontmatter.tags}
                          slug={node.fields.slug}
                          cardItemType="career"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            ) : null}
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <div className="tags">
                  {tags.map(tag => (
                    <Link
                      key={tag + `tag`}
                      className="tag"
                      to={`/tags/${kebabCase(tag)}/`}
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  author: PropTypes.shape({ name: PropTypes.string, email: PropTypes.string }),
  helmet: PropTypes.object,
  jobTags: PropTypes.arrayOf(PropTypes.string),
}
